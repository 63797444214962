.uploadImage {
  display: flex;
  justify-content: center;
}
.uploadImage input {
  margin-bottom: 15px;
}
.viewPhotoModalContent {
  display: flex;
  justify-content: center;
}
.viewPhotoModalContent > div {
  padding: 150px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.impressionsRow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.impressionsRow > span {
  margin-right: 5px;
}
.tilesContent {
  align-items: flex-end;
}
.uploadTileAndLength {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*background: red;*/
  margin: 20px 0;
}
.uploadTileAndLength > .ant-input-number {
  margin: 0 0 10px 38px;
}
.selectFile {
  position: relative;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.selectFile > label {
  border: 1px solid black !important;
  width: 80px;
  height: 80px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}
.impressionLimit {
  margin: 10px 0 0 20%;
}
.impressionLimit > .ant-input-number {
  margin-right: 15px;
}
.selectFile > label > span {
  width: 80px !important;
  height: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageX {
  position: absolute;
  top: -5px;
  left: 90px;
  font-size: 18px;
  cursor: pointer;
  min-height: 100%;
}
.reloadImage {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.colorPicker {
  margin-top: 15px;
}
/*//zoom*/
.zoomModal {
  width: 90% !important;
}
.zoomLoading {
  height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.zoomModal > .ant-modal-content {
  margin: 0 auto;
  width: 80vw;
}
.floorUrlForZoom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.floorUrlForZoom > input {
  margin: 15px 0;
}
.floorUrlForZoom > div > div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}
.roomMaskNonVisible {
  width: 100%;
  opacity: 0;
}
.roomMask {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2 ;
}
.floorForMask {
  position: absolute;
  background-repeat: repeat;
  background-position:  center left;
  z-index: 0;
}

body {
  margin: 0;
  background-color: white;
  font-weight: 200;
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,*/
  /*  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;*/
  /*font-size: 25px;*/
  font-weight: 200;
  text-transform: uppercase;
}

.credit-card {
  width: 380px;
  margin: 0 0 0 -45px;
  height: 210px;
  perspective: 1000px;
  background-color: transparent;
}

.credit-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.credit-card-inner-front {
  animation-name: cardAnimationFront;
  animation-duration: 1s;
}
.credit-card-inner-back {
  animation-name: cardAnimationBack;
  animation-duration: 1s;
  transform: rotateY(180deg);
}
.credit-card-front,
.credit-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 20px;
  background: linear-gradient(to top right, #4db671 0%, #a5b60e 100%);
  padding: 20px;
}

.credit-card-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.credit-card-back {
  transform: rotateY(180deg);
  margin: 0 0 0 -35px;
  /*margin-left: -35px;*/
}

#card-number {
  /*font-family: "Courier New", Courier, monospace;*/
  font-size: 30px;
}

.card-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 435px;
  margin-top: 50px;
}

input::placeholder {
  color: rgb(128, 128, 128);
  font-weight: 100;
}

#card-type {
  text-align: right;
  height: 70px;
}

#card-type svg {
  width: 70px;
  height: 60px;
}

.text-input {
  padding: 15px 15px 15px 0;
  /*padding-left: 0px;*/
  /*box-shadow: 0;*/
  border: 0;
  font-size: 20px;
  /*margin-bottom: 10px;*/
  outline: none;
  margin: 5px 5px 10px 5px;
  background-color: transparent;
  /*color: white;*/
  border-bottom: 1px solid #ff0866;
  font-weight: 100;
  letter-spacing: 1px;
}

.input-label {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: -10px;
  letter-spacing: 1px;

  /*color: white;*/
}

#card-expiration {
  /*display: flex;*/
  /*align-items: center;*/
  /*font-size: 20px;*/
  margin-left: 50%;
  height: 20px;
  display: inline-block;
  letter-spacing: 1px;

}

#validthru {
  font-size: 7.5px;
  width: 25px;
  display: inline-block;
  margin-top: 1px;
}

#card-expiration p {
  padding: 0;
  margin: 0;
  letter-spacing: 1px;

}

#card-holder-name {
  /*font-size: 20px;*/
  height: 20px;
  display: inline-block;
  letter-spacing: 1px;

}

#card-number {
  text-align: center;
  height: 20px;
  display: inline-block;
  letter-spacing: 1px;

}

.card-stripe {
  position: absolute;
  width: 100%;
  background-color: black;
  height: 75px;
  margin-top: 20px;
  margin-left: -20px;
  letter-spacing: 1px;

}

.card-sig-container {
  margin-top: 120px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.signature {
  /*font-family: "Homemade Apple", cursive;*/
  text-transform: lowercase;
  overflow: hidden;
  z-index: 9999;
}

.credits {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}
@keyframes cardAnimationFront {
  from {
    transform: rotateY(180deg);
    duration: "100";
    easing: "linear";
  }
  to {
    transform: rotateY(360deg);
    duration: "100";
    easing: "linear";
  }
}
@keyframes cardAnimationBack {
  from {
    transform: rotateY(360deg);
    duration: "100";
    easing: "linear";
  }
  to {
    transform: rotateY(180deg);
    duration: "100";
    easing: "linear";
  }
}
